.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px 6px 22px;
  border-radius: 4px;
  background: #161220;

  &_name {
    font-size: 18px;
    margin-right: 9px;
  }

  &_pts {
    color: #938aaa;
    margin: 0 9px 0 3px;
  }

  &_avatar {
    margin: 0 15px 0 16px;
  }

  &__scores_block {
    display: flex;
    align-items: center;
  }

  &__user_info {
    display: flex;
    align-items: center;
  }
}
