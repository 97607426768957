@use 'src/src/app/scss/abstracts' as *;

.leaderboard {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__list {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 349px;
    margin: 0;
    padding: 0;
    background: radial-gradient(88.71% 59.74% at 50% 67.19%, #7931ef 0%, rgb(10 6 20 / 0%) 100%);
    list-style: none;
    overflow: hidden;

    // &::after {
    //   content: '';
    //   background: linear-gradient(0deg, #7645ff -10.3%, #010101 55.99%);
    //   position: absolute;
    //   z-index: 1;
    //   width: 100%;
    // }

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 100%;
      height: 138px;
      transform: translateY(40%);
      background: linear-gradient(180deg, rgb(10 6 20 / 0%) 0%, #0a0614 52.25%);
    }
  }

  &__item {
    &:nth-child(1) {
      padding-top: 54px;
    }

    &:nth-child(3) {
      padding-top: 75px;
    }
  }

  &__ends_in {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    gap: 13px;

    &__title {
      display: flex;
      align-items: center;
      gap: 5px;
      color: $color-base-gray-500;
    }
  }
}
