@use 'src/src/app/scss/abstracts' as *;

.image {
  $this: &;

  font-size: 0;
  line-height: 0;
  display: flex;

  &__inner {
    @include skeleton;

    display: inline-flex;
    overflow: hidden;
  }

  &--fill-parent {
    display: block;

    #{$this} {
      &__inner {
        display: block;
        position: relative;
        overflow: hidden;
      }

      &__mobile,
      &__desktop {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        // opacity: 0;
        transition: opacity $transition-fast;
      }
    }
  }

  &--loaded,
  &--loaded-mobile {
    #{$this} {
      &__inner {
        background: none;
      }
    }
  }

  &--loaded {
    #{$this} {
      &__desktop {
        opacity: 1;
      }
    }
  }

  &--loaded-mobile {
    #{$this} {
      &__mobile {
        opacity: 1;
      }
    }
  }

  &--with-mobile-image {
    #{$this} {
      &__mobile {
        display: block;

        @include mq(sm) {
          display: none;
        }
      }

      &__desktop {
        display: none;

        @include mq(sm) {
          display: block;
        }
      }
    }
  }
}
