.winner {
  width: fit-content;
  max-width: 138px;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;

    &_descr {
      margin-top: 8px;
      opacity: 0.5;
    }
  }

  &__name {
    margin-top: 24px;
  }

  &__avatar {
    &_container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 81px;
      height: 81px;
    }

    &_border {
      position: absolute;
      inset: 0;
    }

    &_place {
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
    }

    &_lightening {
      position: absolute;
      top: -6.5px;
      right: -10.5px;
    }
  }
}
