.list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 4px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 25px;

  &__title {
    width: fit-content;
    background-color: #2aa5a0;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(
      90deg,
      #ff63c1 -5.74%,
      #f0ff44 28.16%,
      #44fcfc 58.47%,
      #be6bff 92.04%
    );
  }
}
